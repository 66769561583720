const Services = () =>{

    return (
        <section id="services" className="services">
          <div className="container">
            <div className="section-title">
              <h2>Services</h2>
              <p>From visual design and branding to final delivery of the project to the world, Sillkom have the staff and skills to help. Design, Print, Website development and mobile development all under one roof.</p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bxl-dribbble" /></div>
                  <h4>Website development</h4>
                  <p>New website, rebuilding an excising website and feature enhancement to excising websites.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-file" /></div>
                  <h4>Front end development</h4>
                  <p>From standard HTML/CSS to full interactive React and Angular websites, we have the skills to help.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-tachometer" /></div>
                  <h4>Mobile Application development</h4>
                  <p>Cross platform mobile development for native iOS and Android applications.</p>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-world" /></div>
                  <h4>Nemo Enim</h4>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-slideshow" /></div>
                  <h4>Dele cardo</h4>
                  <p>Quis consequatur saepe eligendi voluptatem consequatur dolor consequuntur</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-arch" /></div>
                  <h4>Divera don</h4>
                  <p>Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit aspernatur</p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
    )
}

export default Services