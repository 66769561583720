const Clients = () =>{
    return (
        <section id="clients" className="clients">
        <div className="container">
          <div className="section-title">
            <h2>Previous contract clients</h2>
          </div>
        </div>
        <div className="container">
          <div className="row no-gutters clients-wrap clearfix wow fadeInUp">
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="assets/img/clients/client-royal-london.png" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="assets/img/clients/client-wbac.png" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="assets/img/clients/client-gp.png" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="assets/img/clients/client-nice.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

    )
}

export default Clients