const Hero = () =>{
    return (
        <section id="hero" className="d-flex flex-column justify-content-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h1>Creative web and mobile application design</h1>
              <h2>Designer and developer, making concepts a reality</h2>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Hero