import { HashLink as Link } from 'react-router-hash-link';
import AboutUs from './components/AboutUs';
import Clients from './components/Clients';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Services from './components/Services';

function App() {
  return (
    <div>
       <header id="header" className="fixed-top ">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-9 d-flex align-items-center justify-content-between">
              <h1 className="logo"><a href="index.html">Sillkom.com</a></h1>
              {/* Uncomment below if you prefer to use an image logo */}
              {/* <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
              <nav className="nav-menu d-none d-lg-block">
                <ul>
                  <li className="active"><Link to="#" >Home</Link></li>
                  <li><Link to="#about">About</Link></li>
                  <li><Link to="#services">Services</Link></li>
                  <li><Link to="#clients">Clients</Link></li>
                  <li><Link to="#contact">Contact</Link></li>
                </ul>
              </nav>{/* .nav-menu */}
              <Link to="#about" className="get-started-btn scrollto">Get Started</Link>
            </div>
          </div>
        </div>
      </header>
      {/* ======= Hero Section ======= */}
      <Hero />
      <main id="main">
        {/* ======= About Us Section ======= */}
        <AboutUs />
        {/* ======= Services Section ======= */}
        <Services />
        {/* ======= Cta Section ======= */}
        {/* <CallToAction /> */}
        {/* ======= Features Section ======= */}
        {/* <Features /> */}
        {/* ======= Clients Section ======= */}
        <Clients />
        {/* ======= Contact Section ======= */}
        <ContactUs />
      </main>{/* End #main */}
      {/* ======= Footer ======= */}
      <Footer />
      <div id="preloader" />
      <Link to="#" className="back-to-top"><i className="ri-arrow-up-line" /></Link>
    </div>
  );
}

export default App;
