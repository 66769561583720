const Footer = () =>{

    return (
        <footer id="footer">
        <div className="container">
          <h3>Sillkom</h3>
          <p>Creative digital web and mobile application design.</p>
          {/* <div className="social-links">
            <a href="#" className="twitter"><i className="bx bxl-twitter" /></a>
            <a href="#" className="facebook"><i className="bx bxl-facebook" /></a>
            <a href="#" className="instagram"><i className="bx bxl-instagram" /></a>
            <a href="#" className="google-plus"><i className="bx bxl-skype" /></a>
            <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
          </div> */}
          <div className="copyright">
            © Copyright <strong><span>Sillkom.com</span></strong>. All Rights Reserved : Company No. 11746464
                </div>
          {/* <div className="credits">
                  Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div> */}
        </div>
      </footer>
    )
}

export default Footer